import { CreateRegister, UpdateUser, SendRegister } from "@/@types/register";
import { AddPet, NewReservation, NewReservationsData } from "@/@types/reservation";
import axios from "@/plugins/axios";

class MembersService {
  //会員情報CRUD
  get(id: string): Promise<any> {
    return axios.get(`/membership/members/${id}`);
  }

  getAll(): Promise<any> {
    return axios.get(`/membership/members`);
  }

  getKeeperByAnirece(cognitoId: string, facilityId: string): Promise<any> {
    return axios.get(`/membership/members/${cognitoId}/keeper_animal`, { params: { facility_id: facilityId } });
  }

  create(data: CreateRegister): Promise<any> {
    return axios.post("/membership/members", data);
  }

  update(id: string, data: UpdateUser): Promise<any> {
    return axios.put(`/membership/members/${id}`, data);
  }

  delete(id: string): Promise<any> {
    return axios.delete(`/membership/members/${id}`, { params: { is_permanent_delete: "true" } });
  }

  // 会員登録リンク送信
  sendRegister(data: SendRegister): Promise<any> {
    return axios.post("/membership/temporary_link", data);
  }
  //会員登録一時データ取得
  getPreRegisterInfo(id: string): Promise<any> {
    return axios.get(`/membership/temporary_link/${id}`);
  }

  // ペットCRUD
  createPet(id: string, data: AddPet): Promise<any> {
    return axios.post(`/membership/members/${id}/pets`, data);
  }

  getPetsByMemberId(id: string, is_enabled: boolean): Promise<any> {
    return axios.get(`/membership/members/${id}/pets`, { params: { is_enabled } });
  }
  getPet(id: string, pet_id: string): Promise<any> {
    return axios.get(`/membership/members/${id}/pets/${pet_id}`);
  }
  updatePet(id: string, pet_id: string, data: AddPet): Promise<any> {
    return axios.put(`/membership/members/${id}/pets/${pet_id}`, data);
  }
  deletePet(id: string, pet_id: string): Promise<any> {
    return axios.delete(`/membership/members/${id}/pets/${pet_id}`);
  }

  //予約CRUD
  // createReservation(member_id: string, data: NewReservationsData[]): Promise<any> {
  //   return axios.post(`/membership/members/${member_id}/reservations_list`, data);
  // }
  createReservation(member_id: string, data: NewReservationsData[]): Promise<any> {
    return axios.post(`/membership/members/${member_id}/reservations_list_anirece`, data);
  }

  getReservationsByMemberId(member_id: string): Promise<any> {
    return axios.get(`/membership/members/${member_id}/reservations`);
  }
  getReservationDetail(member_id: string, reservation_id: string): Promise<any> {
    return axios.get(`/membership/members/${member_id}/reservations/${reservation_id}`);
  }
  updateReservation(member_id: string, reservation_id: string, data: NewReservation): Promise<any> {
    return axios.put(`/membership/members/${member_id}/reservations/${reservation_id}`, data);
  }
  deleteReservation(member_id: string, reservation_id: string): Promise<any> {
    return axios.delete(`/membership/members/${member_id}/reservations/${reservation_id}`);
  }
  // パスワードリセット一時リンク生成
  sendResetPasswordLink(email: string) {
    return axios.post("membership/temporary_link/password", { email });
  }
  // パスワードリセット情報取得
  getResetPasswordInfo(id: string) {
    return axios.get(`membership/temporary_link/password/${id}`);
  }
  //予約可能枠取得
  getPossibleReservations(hospital_id: string, course_id: string, year: string, month: string) {
    return axios.get(`membership/facilities/${hospital_id}/reservations`, { params: { course_id, year, month } });
  }
}

export default new MembersService();
