
import { Component, Vue } from "vue-property-decorator";
import { SendRegister } from "@/@types/register";
import MemberService from "@/services/members";
import HospitalService from "@/store/hospital";
@Component
export default class EmailForm extends Vue {
  private get hospitalId() {
    return HospitalService.hospitalId;
  }
  private form: SendRegister = {
    email: "",
    facility_id: this.hospitalId ? this.hospitalId : "",
  };
  private confirmEmail = "";

  private async submit() {
    try {
      if (!this.form.facility_id) {
        delete this.form.facility_id;
      }
      await MemberService.sendRegister(this.form);
      this.$router.push({ name: "SentRegisterSuccess" });
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
