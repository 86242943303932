
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import EmailForm from "@/components/forms/EmailForm.vue";

@Component({
  name: "SentRegister",
  components: {
    Header,
    Footer,
    EmailForm,
  },
})
export default class SentRegister extends Vue {}
